import React from 'react'
import { graphql } from 'gatsby'
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from "react-bootstrap";
import loadable from "@loadable/component";
import LayoutTwo from '../components/layoutTwo';
import SEO from "../components/seo";

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const StaticIntro = loadable(() => import("../components/StaticIntro/StaticIntro"));
const OfficeCard = loadable(() => import("../components/OfficeCard/OfficeCard"));

const ContactPageTemplate = ({ data }, props) => {
    const PageData = data?.strapiPage
    const Offices = data?.allStrapiOffice.edges

    return (
        <LayoutTwo popularSearch={PageData?.select_popular_search?.title}>
            <div className="layout-padding-top"></div>
            <BreadcrumbModule subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title} subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug} parentlabel={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title} parentlink={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug} title={PageData.title} />

            {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
                return (
                    <>
                        {module.strapi_component === "page-modules.plain-content" &&
                            <StaticIntro
                                title={module.title}
                                staticClass="static-intro-secondary"
                                {...module}
                            />
                        }
                    </>
                )
            })}

            <div className="office-card-wrapper-main">
                <Container>
                    <Row>
                        {
                            Offices && Offices.length > 0 && Offices.map((office, i) => {
                                return (
                                    <Col md={6} key={i}>
                                        <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={(i + 1) * 300} offset={50}>
                                            <OfficeCard officeDetail={office} />
                                        </ScrollAnimation>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </div>
        </LayoutTwo>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
    )
}

export default ContactPageTemplate


export const query = graphql`
query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
                Add_Page_Modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
              ...PlainContentFragment
            }
        }
    }

    allStrapiOffice(filter: {publish: {eq: true}}) {
        edges {
            node {
                ...OfficeFragment
            }
        }
    }
}`